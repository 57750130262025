/* Add here all your css styles (customizations) */

// Variables
@import "custom.variables";

// Mixins
@import "custom.mixins";

@font-face {
	font-family: 'WorkSans';
	font-style: normal;
	font-weight: 400;
	font-display: auto;
	src: url("/content/assets/include/fonts/WorkSans-Regular.ttf");
}

@font-face {
	font-family: 'WorkSans';
	font-style: normal;
	font-weight: 600;
	font-display: auto;
	src: url("/content/assets/include/fonts/WorkSans-SemiBold.ttf");
}

body {
	font-family: 'WorkSans';
	font-weight: 400;
	color: #1d2024;
	font-size: 18px;
}

a, .social-links svg {
	color: $link-color;
}

a:hover {
	color: #000;
}


@media (min-width: 1200px) {
	.container {
		max-width: 1320px;
	}
}

.g-mb-30 {
	margin-bottom: 30px;
}

.tabs .btn {
	background-color: #efefef;
	border-color: #efefef;
	color: #262a2f;
	font-size: 18px;
	border-radius: 6px;
	margin-bottom: 6px;
}

.tabs-title {
	margin-bottom: 34px;
}

.tabs .btn:hover {
	background-color: #e4e3e3;
	border-color: #e4e3e3;
	color: #262a2f;
	font-size: 18px;
}

.btn-primary {
	background-color: #ffcb00 !important;
	border-color: #ffcb00 !important;
	color: #262a2f;
}

form .btn-primary {
	background-color: #ffcb00 !important;
	border-color: #ffcb00 !important;
	color: #262a2f;
	font-size: 23px;
	padding: 15px 30px;
	text-transform: uppercase;
}

.btn-primary:hover {
	background-color: #f4cb29;
	border-color: #f4cb29;
}

.paginationBox {
	text-align: center;
}

.paginationBox ul, .paginationBox ul li {
	display: inline-block;
}

.paginationBox ul li a {
	display: inline-block;
	padding: 6px 16px;
	margin: 0 1px;
	border-radius: 6px;
	text-decoration: none;
	border: 1px solid #7c7c7c;
}

.paginationBox ul li a:hover {
	background: #F5f5f5;
}

.paginationBox ul li.active a, .paginationBox ul li.active a:hover {
	background: #ffcb00;
}



.tabs .collapse a {
	background-color: #fff;
	display: block;
	height: 100%;
	width: 100%;
	border: 2px solid #efefef;
	border-radius: 6px;
	padding: 15px 20px;
	color: #1d2024;
	margin-bottom: 0 !important;
	font-size: 18px;
	text-decoration: none;
}

.tabs .collapse a:hover {
	background-color: #ffcb00;
	border: 2px solid #ffcb00;
	color: #1d2024;
	text-decoration: none;
}

.nav-link {
	color: #fff !important;
	font-size: 18px;
	font-weight: 600;
}

header, section {
	margin-bottom: $section-mb;
}

.text-right {
	text-align: right;
}


.navbar-expand-lg .navbar-collapse {
	justify-content: end;
}

.slider-area {
	margin-bottom: 70px !important;
}

.slick-slide {
	background-position: center;
	background-size: cover;
	height: 350px;
}

.slick-slide h2, .slick-slide h3, .slick-slide h4 {
	color: #fff;
}

article figure {
	height: 270px;
	overflow: hidden;
	text-align: center;
}

article figure img {
	width: 100%;
}

#background-video {
	width: 2560px;
	height: 700px;
	object-fit: cover;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: -1;
	margin: 0 auto;
}

#background-video-mobile {
	width: 100%;
	height:auto;
	object-fit: cover;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: -1;
	margin: 0 auto;
	display: none;
}

.header-bg {
	background: #151d2a;
	opacity: 0.8;
	height: 102px;
	position: fixed;
	width: 100%;
	z-index: 599;
}

.subpage .header-bg {
	display: none;
}

.subpage {
	padding-top: 102px;
}

.subpage header {
	background: url("/content/assets/img/bgheader.jpg") no-repeat 0 center;
	height: 102px;
	position: fixed;
	width: 100%;
	top: 0;
	margin-bottom: 0;
}

.home-top {
	background: url("/content/assets/img/video_gradient.png") repeat-x 0 bottom;
	margin-bottom: 0px !important;
	padding-bottom: 30px;
}

.top-title {
	padding-top: 80px;
	margin-bottom: 25px;
}

.top-title2 {
	padding-top: 80px;
	margin-bottom: 0;
}

h1 {
	margin-bottom: 25px;
	font-weight: 600;
	font-size: 36px;
}

.top-title h1 {
	margin-bottom: 0;
}

.navbar {
	padding: 10px 0 11px;
}

.nav-pills {
	margin: 20px 0 30px;
	border-bottom: 1px solid #dedede;
}

.nav-pills button {
	width: 150px;
	padding: 10px 20px;
	text-align: center;
	text-transform: uppercase;
	background: #fff;
	margin-right: 2px;
	border-right: 1px solid #dedede;
	border-top: 1px solid #dedede;
	border-bottom: 0;
	border-left: 1px solid #dedede;
}

.nav-pills button.active {
	background: #ffcb00;
	border: 1px solid #ffcb00;
}

.mb-20 {
	margin-bottom: 20px;
}

.mb-40 {
	margin-bottom: 40px;
}

footer a {
	text-decoration: none;
}

footer a:hover {
	text-decoration: underline;
}
@media (min-width: 768px) {

	.tabs .collapse a {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		margin-bottom: 0;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		height: 106px;
	}
}


@media (min-width: 992px) {

	.nav-link {
		display: block;
		padding: 42px 1.3rem 0 1.3rem;
	}



	.mobile-display {
		display: none !important;
	}

	.nav-link {
		color: #fff !important;
		font-size: 18px;
		font-weight: 600;
	}

	.dropdown-menu .nav-link {
		/*color: #000 !important;*/
		font-size: 16px;
		font-weight: 200;
		padding: 6px 15px;
	}
	/*.nav .dropdown-menu[data-bs-popper] {
		width: 101%;
		margin-top: -1px;
		border-radius: 0;
	}

	.nav .dropdown {
		position: static;
		border-radius: 0;
	}

	

	.header .dropdown-menu {
		z-index: 9999 !important;
	}

	.submenu-container {
		width: 1320px;
		margin: 0 auto;
		display: table;
		padding: 0!important;
	}

	.submenu[data-bs-popper] {
		top: 0;
		left: auto;
		margin-top: 0;
		right: auto;
	}

	.submenu a {
		color: #fff;
	}*/
	header .dropdown-menu {
		background-color: #151d29;
		width: 300px;
		margin-top: 16px !important;
		border-radius: 0 !important;
		opacity: 0.9 !important;
	}

	.layout-home header .dropdown-menu {
		opacity: 0.8 !important;
	}

	header .dropdown-menu .submenu {
		padding-left: 6px;
		list-style-type: none;
	}
}

.languages, .languages a {
	color: #fff !important;
	font-size: 18px;
	text-transform: uppercase;
	text-decoration: none;
}

.languages {
	padding: 40px 0 0 110px;
	line-height: 1.3em;
}

.languages ul, .languages ul li {
	margin: 0 !important;
}

.languages .list-inline-item {
	padding: 0 1px;
}

.languages .list-inline-item:first-child {
	border-right: 1px solid #5c6068;
	padding-right: 8px;
}

.nav .dropdown-toggle::after {
	display: none;
}

.social-links img {
	width: 60px;
	margin-right: 10px;
	margin-top: 10px;
}

.home-top {
	padding-top: 330px;
	position: relative;
	z-index: 1;
}

.home-top h2 {
	color: #fff;
	font-size: 90px;
	line-height: 86px;
	padding-right: 10px;
	font-weight: 600;
	text-transform: uppercase;
}

.home-top a {
	color: #ffcb00;
	display: block;
	font-weight: 600;
	font-size: 28px;
	line-height: 33px;
	text-transform: uppercase;
	text-decoration: none;
	padding-top: 90px;
}

/*.home-top a span {
	padding-top: 90px;
}*/
		.home-top .row {
			margin-bottom: 0;
		}

		.home-top .row .col-lg-3 {
			margin-top: 4px;
		}

		.history {
			padding: 60px 0 50px 0;
			margin-bottom: 0;
			background: #ffcc05;
		}

.home-projects {
	padding: 545px 0 60px;
	margin-bottom: 0;
	background: url("/content/assets/img/bgprojects.jpg") no-repeat top center #13213c;
}

.home-projects .row {
	border-top: 8px solid #ffcb00;
	background: rgba(7, 18, 31, .75);
}

		.u-header {
			position: fixed;
			z-index: 901;
			width: 100%;
		}

		footer {
			background: #efefef !important;
			padding: 60px 0;
		}

		.history h2 {
			font-size: 60px;
			font-weight: 600;
			text-transform: uppercase;
			margin-bottom: 18px;
		}

		.history p {
			font-size: 19px;
			margin-bottom: 29px;
		}

		.history strong {
			font-size: 85px;
			line-height: 85px;
			display: block;
			font-weight: 400;
			margin-bottom: 10px;
			text-transform: uppercase;
		}

		.history span {
			font-size: 22px;
			line-height: 22px;
			text-transform: uppercase;
		}

		.history h3 {
			font-size: 32px;
			line-height: 32px;
			padding: 0;
			margin: 40px 0 25px 0;
			font-weight: 600;
			background: url("/content/assets/img/lineh3.png") repeat-x center 14px;
			text-transform: uppercase;
		}

		.history h3 span {
			padding: 0 22px;
			font-size: 32px;
			line-height: 32px;
			background: #ffcc05;
		}

		.home-projects h2 {
			font-size: 60px;
			font-weight: 600;
			color: #ffcb00;
			text-transform: uppercase;
			margin-bottom: 15px;
		}

.home-projects .col-md-4 {
	padding: 35px 30px 80px 30px;
	text-align: left;
	font-size: 16px;
	color: #fff;
	position: relative;
	border-right: 1px solid #202b4e;
}

		.home-projects .col-md-4:last-child {
			border-right: 0;
		}

		.home-projects .col-md-4 a {
			color: #fff;
			text-decoration: none;
		}

		.home-projects .col-md-4 a:hover {
			text-decoration: underline;
		}

.home-projects .col-md-4 a.alllink {
	opacity: 0.75;
}

.home-projects h3 {
	font-size: 28px;
	line-height: 28px;
	padding: 0;
	margin: 0 0 20px 0;
	font-weight: 300;
	color: #ffcb00;
	text-transform: uppercase;
}

		.alllink {
			padding: 0 0 0 22px;
			margin: 0;
			display: block;
			background: url("/content/assets/img/arrow.png") no-repeat 4px center;
			position: absolute;
			bottom: 29px;
		}

.home-projects .alllink {
	background: url("/content/assets/img/arrow2.png") no-repeat 4px center;
}
	footer p {
		margin: 0;
		line-height: 26px;
	}

	footer .col-md-3:last-child p {
		font-size: 16px;
	}

	.navbar-toggler .navbar-toggler-icon {
		background: url(/content/assets/img/m_menu_icon1.svg) no-repeat;
	}

	.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
		background: url(/content/assets/img/m_menu_icon2.svg) no-repeat;
	}

	.navbar-toggler.collapsed .navbar-toggler-icon {
		background: url(/content/assets/img/m_menu_icon1.svg) no-repeat;
	}

	.service-subtitle .row {
		background-repeat: no-repeat;
		background-position-x: 15px;
		height: 500px;
	}

	.navbar-toggler:focus {
		border: 0 !important;
		box-shadow: none !important;
	}

	.subtitle {
		background: #ffcd0a;
		font-size: 27px;
		font-weight: 600;
		opacity: 0.9;
		height: 280px;
		margin-top: 185px;
		padding: 120px 30px 0;
		line-height: 35px;
		text-transform: uppercase;
	}

.subtitle img {
	margin-bottom: 15px;
}

	.breadcrumb {
		background: none;
		padding: 0;
		margin-bottom: 15px;
		color: #aaaaac;
		font-size: 14px;
	}

	.breadcrumb li {
		background: url(/content/assets/img/breadcrumb.png) no-repeat right 12px;
		padding: 0 23px 0 7px;
	}

	.breadcrumb li:first-child {
		padding-left: 0;
	}

	.breadcrumb li:last-child {
		background: none;
	}

	.breadcrumb a {
		color: #aaaaac;
	}

	blockquote {
		color: #969798;
		font-size: 24px;
		font-weight: 600;
		line-height: 1.3em;
		margin-bottom: 30px;
		padding: 0 0 0 110px;
		background: url(/content/assets/img/blockquote.png) no-repeat 17px 7px;
	}

	.service-subtitle {
		margin-bottom: 40px;
	}

	.photo-video {
		color: #969798;
		font-size: 18px;
		margin-bottom: 30px;
		margin-right: 0;
		margin-left: 0;
		background: url(/content/assets/img/bgphotovideo.png) no-repeat #efefef;
	}

	.photo-video .col-lg-8 div {
		padding: 17px 12px 0 30px;
	}

	.photo-video a {
		margin-bottom: 15px;
		display: inline-block;
		opacity: 0.9;
		font-size: 26px;
		width: 235px;
		padding: 35px 15px 15px 15px;
		font-weight: 600;
		border: 1px solid #ffe57e;
		background: #fad647;
		color: #000;
	}

	.photo-video a:hover {
		text-decoration: none;
		background: #f8d85a;
	}

	.photo-video a:first-of-type {
		margin-right: 15px;
	}

	.photo-video h2 {
		margin-bottom: 2px;
		font-size: 42px;
		font-weight: 200;
		color: #000;
	}

	.photo-video p {
		color: #606060;
		margin-right: 75px;
		margin-bottom: 6px;
	}

	.staticpage h2 {
		color: #ffcb00;
		font-size: 51px;
		line-height: 51px;
		font-weight: 600;
		margin-bottom: 20px;
	}

	.staticpage p, .staticpage ul, .staticpage ol {
		margin-bottom: 25px;
	}

	.staticpage h3 {
		color: #262a2f;
		font-size: 24px;
		line-height: 24px;
		font-weight: 600;
		margin-bottom: 20px;
		padding-top: 15px;
	}

	.staticpage ul {
		list-style: square;
	}

.staticpage ul li {
	margin-bottom: 6px;
}

	.staticpage ul li::marker {
		color: #ffcb00;
		font-size: 23px;
		line-height: 23px;
	}

textarea {
	height: 220px;
}

.d-block-mobile2 {
	display: none;
}

input[type="checkbox"] {
	height: 20px;
	width: 20px;
}

	.rightmenu h3 {
		color: #262a2f;
		font-size: 28px;
		font-weight: 600;
		line-height: 28px;
		display: inline-block;
		margin-bottom: 35px;
		padding-bottom: 4px;
		border-bottom: 7px solid #ffcb00;
	}



	.rightmenu ul {
		list-style-type: none;
		margin: 0 0 15px 0;
		padding: 0;
		font-size: 18px;
	}

	.rightmenu ul li {
		list-style-type: none;
		margin: 0 0 8px 0;
		padding: 0;
	}

	.rightmenu ul li a {
		color: #262a2f;
		text-decoration: none;
	}

	.rightmenu ul li a:hover {
		text-decoration: underline;
	}

	.rightmenu .active a {
		border-bottom: 4px solid #ffcb00;
	}

	.rightmenu .active a:hover {
		text-decoration: none;
	}

	.content {
		padding-bottom: 30px;
	}

	.text-subtitle {
		text-transform: uppercase;
		font-weight: 600;
		margin-bottom: 0 !important;
	}

	.home-top a.num1 {
		background: url(/content/assets/img/menu01.png) no-repeat;
	}

	.home-top a.num2 {
		background: url(/content/assets/img/menu02.png) no-repeat;
	}

	.home-top a.num3 {
		background: url(/content/assets/img/menu03.png) no-repeat;
	}

	.home-top a.num4 {
		background: url(/content/assets/img/menu04.png) no-repeat;
	}

	.home-top a.num5 {
		background: url(/content/assets/img/menu05.png) no-repeat;
	}

	.home-top a.num6 {
		background: url(/content/assets//img/menu06.png) no-repeat;
	}

	.home-top a.num1:hover {
		background: url(/content/assets/img/menu01on.png) no-repeat;
	}

	.home-top a.num2:hover {
		background: url(/content/assets/img/menu02on.png) no-repeat;
	}

	.home-top a.num3:hover {
		background: url(/content/assets/img/menu03on.png) no-repeat;
	}

	.home-top a.num4:hover {
		background: url(/content/assets/img/menu04on.png) no-repeat;
	}

	.home-top a.num5:hover {
		background: url(/content/assets/img/menu05on.png) no-repeat;
	}

	.home-top a.num6:hover {
		background: url(/content/assets/img/menu06on.png) no-repeat;
	}

	.home-top a {
		transition: 0.3s;
	}

	.home-top a span {
		display: block;
		/*background: rgb(20,28,40);
	background: linear-gradient(0deg, rgba(20,28,40,1) 0%, rgba(20,28,40,0.25252107679009106) 100%);*/
	}

	.home-top a:hover {
		text-decoration: none;
	}

	.content img {
		max-width: 100%;
	}

	.gallery-small img {
		max-width: 100%;
	}

	.gallery-small {
		margin-bottom: 10px;
	}

	.gallery-small a {
		display: block;
		position: relative;
		z-index: 0;
		height: 190px;
		overflow: hidden;
		margin-bottom: 20px;
	}

	.gallery-small a img {
		max-width: 100%;
		margin-bottom: 20px;
		object-fit: cover;
		height: 190px;
	}

	.file-icon-pdf {
		background: url(/content/assets/img/pdf.png) no-repeat;
		width: 41px;
		height: 48px;
		display: block;
	}

	.file-card {
		border: 2px solid #ffcb00;
		border-radius: 4px;
		padding: 20px 10px;
		margin-bottom: .71429rem !important;
	}

	.g-mt-6 {
		margin-top: 3px !important;
	}

	.g-mr-15 {
		margin-right: 14px !important;
	}

	.g-mb-3 {
		margin-bottom: .21429rem !important;
	}

	.g-mt-20 {
		margin-top: 1.42857rem !important;
	}

	.media {
		display: flex;
		align-items: flex-start;
	}

	.media-body {
		flex: 1;
	}

	.g-width-60 {
		width: 60px;
	}

	.media-body a {
		font-size: 18px;
		color: #000;
		text-decoration: underline;
	}

	.file-info {
		font-size: 14px;
		color: #a3a3a3;
	}

	h3.h3 {
		font-size: 36px;
		padding-top: 20px;
	}

	.back {
		font-size: 18px;
		line-height: 18px;
		color: #262a2f;
		padding-left: 21px;
		display: block;
		margin-bottom: 45px;
		text-transform: uppercase;
		background: url(/content/assets/img/back.png) no-repeat left 4px;
	}

	.mobile-top {
		padding: 15px 20px;
		background: #f9f9f9;
		margin-bottom: 30px;
	}

	.right-project {
		margin-bottom: 40px;
		margin-top: 22px;
	}

	.right-project p, .mobile-top p {
		font-size: 16px;
		line-height: 16px;
		color: #262a2f;
		margin-bottom: 16px;
	}

	.right-project p.title, .mobile-top p.title {
		font-size: 18px;
		font-weight: 600;
		line-height: 18px;
		color: #262a2f;
		border-bottom: 4px solid #ffcb00;
		padding-bottom: 3px;
		display: inline-block;
		margin-bottom: 15px;
		padding-top: 20px;
		text-transform: uppercase;
	}

	@media (max-width: 991px) {

		.d-block-mobile2 {
			display: block;
		}

		.display-none-mobile {
			display: none;
		}

		.dropdown-menu[data-bs-popper] {
			position: static;
			background: none !important;
		}

		.dropdown-menu[data-bs-popper] .submenu {
			padding-left: 1rem;
		}

		.dropdown-menu[data-bs-popper] .submenu .nav-link {
			color: #ffcd0a !important;
			font-size: 16px;
			font-weight: 600;
			padding: 3px 6px;
		}

		.nav-link.dropdown-toggle.show {
			padding-bottom: 0 !important;
		}

		.languages {
			padding: 33px 0 0 0px;
		}

		.navbar-toggler {
			margin-top: 32px;
		}

		.navbar-collapse {
			background: #151d2a;
			opacity: 1;
		}

		.navbar-collapse .nav li {
			display: block;
			width: 100%;
		}

		.navbar-collapse {
			margin-top: 11px;
		}

		.mobile-top {
			display: block !important;
		}

		.mobile-none {
			display: none !important;
		}

		.top-title2 {
			padding-top: 40px;
		}
	}

	@media (min-width: 992px) and (max-width: 1200px) {
		.home-top h2 {
			font-size: 60px;
			line-height: 60px;
		}

		.home-top a {
			font-size: 21px;
			line-height: 26px;
		}

		.home-top {
			padding-top: 250px;
		}

		.nav-link {
			padding: 42px 0.5rem 0;
		}

		.languages {
			padding: 33px 0 0 30px;
		}

		.home-projects {
			padding: 545px 0 60px;
		}

		.gallery-small, .gallery-small a img {
			height: 130px;
		}

		.service-subtitle .col-lg-3 {
			flex: 0 0 35%;
			max-width: 35%;
		}

		.service-subtitle .col-lg-9 {
			flex: 0 0 65%;
			max-width: 65%;
		}

		.home-top .col-md-4 {
			margin-bottom: 20px;
		}
	}


	@media (min-width: 768px) and (max-width: 991px) {
		.tabs .col-sm-6 {
			width: 50%;
		}

		.d-block-mobile2 .col-lg-12 {
			display: flex;
		}

		.d-block-mobile2 .col-lg-12 p {
			width: 33%;
			margin-top: 27px;
		}

		.d-block-mobile2 .col-lg-12 p:first-child {
			margin-top: 0;
		}

		.home-top {
			padding-top: 109px;
		}

		.home-top a {
			padding-top: 88px;
			font-size: 21px;
			line-height: 24px;
		}

		.home-top .row {
			margin-bottom: 0;
		}

		.home-top .col-md-4 {
			margin-bottom: 20px;
		}

		.home-top h2 {
			font-size: 50px;
			line-height: 50px;
		}

		.history h2 {
			font-size: 34px;
		}

		.history strong {
			font-size: 40px;
			line-height: 40px;
			margin-bottom: 4px;
		}

		.history span {
			font-size: 12px;
			line-height: 12px;
		}

		.history span br {
			float: left;
		}

		.history h3, .history h3 span {
			font-size: 25px;
			line-height: 25px;
		}

		.history h3 {
			background-position: center 11px;
		}

		.home-projects {
			padding: 545px 0 30px;
		}

		.home-projects h2 {
			font-size: 30px;
			color: #fff;
		}

		.alllink {
			font-size: 0.9em;
		}

		.home-projects .col-md-4 {
			padding: 25px 20px 60px;
		}

		.home-projects .col-md-4 p {
			margin-bottom: 6px;
			font-size: 0.9em;
		}

		.home-projects h3 {
			font-size: 23px;
			line-height: 23px;
		}

		.photo-video .col-md-12 {
			padding: 10px 25px;
		}

		.photo-video h2 {
			font-size: 32px !important;
		}

		.photo-video a {
			width: 205px;
			padding: 10px 15px 10px !important;
			font-size: 22px;
		}

		.languages {
			padding: 33px 0 0 30px;
		}

		.service-subtitle .row {
			height: 400px;
		}

		.subtitle {
			margin-top: 205px;
			font-size: 24px;
			height: 180px;
			padding: 25px 30px 0;
		}

		.staticpage h2, .staticpage h3 {
			font-size: 41px;
			line-height: 41px;
		}

		.home-top h2 {
			margin-bottom: 20px;
			margin-top: 30px;
		}



		.history span {
			display: inherit;
			line-height: 17px;
			font-size: 14px;
		}

		.history h3 span {
			display: initial;
		}
	}

	@media (max-width: 767px) {
		.d-block-mobile {
			display: block !important;
			height: 10px;
		}

		.home-top a {
			opacity: 1;
		}

		#background-video {
			width: auto;
			height: 635px;
		}

		.tabs .col-sm-6 {
			width: 100%;
		}

		.subtitle {
			background: #ffcd0a;
			font-size: 27px;
			font-weight: 600;
			opacity: .9;
			height: 130px;
			margin-top: 238px;
			padding: 25px 30px 0;
			line-height: 35px;
			text-transform: uppercase;
		}

		.subtitle img {
			width: 50px;
		}


		.home-top .row {
			margin-bottom: 0;
		}

		.photo-video .col-md-12 {
			padding: 10px 25px;
		}

		.photo-video h2 {
			font-size: 32px !important;
		}

		.photo-video a {
			width: 205px;
			padding: 10px 15px 10px !important;
			font-size: 22px;
		}

		.service-subtitle .row {
			background-position-x: 0;
			height: 400px;
		}

		.top-title {
			padding-top: 40px;
		}

		.navbar .d-flex.text-dark {
			padding-left: 16px;
		}

		.navbar .navbar-toggler {
			margin-right: 16px;
		}

		.home-top {
			padding-top: 132px;
		}

		.home-top a {
			padding-top: 83px;
			font-size: 22px;
			line-height: 23px;
		}

		.home-top .row .col-lg-3 {
			margin-bottom: 15px;
		}
		/*.home-top a.num1 {
			background: url(../../img/menu01on.png) no-repeat;
		}

		.home-top a.num2 {
			background: url(../../img/menu02on.png) no-repeat;
		}

		.home-top a.num3 {
			background: url(../../img/menu03on.png) no-repeat;
		}

		.home-top a.num4 {
			background: url(../../img/menu04on.png) no-repeat;
		}

		.home-top a.num5 {
			background: url(../../img/menu05on.png) no-repeat;
		}

		.home-top a.num6 {
			background: url(../../img/menu06on.png) no-repeat;
		}
*/
		.home-top h2 {
			font-size: 50px;
			line-height: 48px;
		}

		.history h2 {
			font-size: 34px;
		}

		.history strong {
			font-size: 40px;
			line-height: 40px;
			margin-bottom: 4px;
		}

		.history span {
			font-size: 12px;
			line-height: 16px;
			display: inherit;
		}

		.history h3 span {
			display: initial;
		}

		.history span br {
			float: left;
		}

		.history h3, .history h3 span {
			font-size: 25px;
			line-height: 25px;
		}

		.history h3 {
			background-position: center 11px;
		}

		.home-projects {
			padding: 30px 0 30px;
		}

		.home-projects h2 {
			font-size: 30px;
			color: #fff;
		}

		.alllink {
			position: static;
		}

		.home-projects .col-md-4 {
			padding: 25px 20px 0px;
			border-right: 0;
		}

		.home-projects .col-md-4 p {
			margin-bottom: 6px;
		}

		.home-projects .row {
			padding-bottom: 20px;
		}

		.home-projects h3 {
			font-size: 23px;
			line-height: 23px;
		}

		footer {
			text-align: center;
			padding: 30px 0;
		}

		.mobile-top p.title {
			padding-top: 10px;
		}

		blockquote {
			font-size: 18px;
		}

		h3.h3 {
			font-size: 30px;
		}



		.home-top h2 {
			margin-bottom: 20px;
		}
	}

	@media (max-width: 575px) {


		.navbar-collapse {
			margin-top: 14px;
		}

		blockquote {
			font-size: 18px;
			font-weight: 600;
			line-height: 1.3em;
			padding: 0 0 0 0;
			background: none;
		}

		.gallery-small a {
			height: auto;
			overflow: auto;
		}

		.gallery-small a img {
			width: 100%;
			margin-bottom: 0;
			object-fit: cover;
			height: auto;
		}

		.home-projects {
			padding: 30px 0 0px !important;
			
		}

		
		footer {
			border-top: 1px solid #e4e3e3;
		}

		.home-projects .row {
			padding-bottom: 40px;
		}


		.navbar img {
			width: 100px;
		}

		.navbar .d-flex.text-dark {
			padding-left: 0;
		}

		.navbar .navbar-toggler {
			margin-right: 0;
			margin-top: 12px;
		}

		.languages {
			padding: 13px 0 0 10px;
			flex: auto;
		}

		.header-bg, .subpage header {
			height: 76px;
		}

		.col-sm-5, .col-sm-6 {
			flex: 0 0 auto;
			width: 50%;
		}

		.home-top a {
			padding-top: 50px;
			font-size: 18px;
			line-height: 21px;
			background-size: auto 47% !important;
		}

		.home-top .text-right {
			text-align: right !important;
		}

		.home-top h2 br {
			float: none;
		}

		.home-top h2 {
			font-size: 59px;
			line-height: 59px;
		}

		.navbar-collapse {
			margin-top: 0;
			/*position: absolute;
			top: 74px;
			left: 0px;*/
			padding-top: 10px;
			padding-bottom: 20px;
		}

		.history h3, .history h3 span {
			font-size: 22px;
			line-height: 25px;
		}

		.history h3 {
			margin: 20px 0 25px;
		}

		.history strong {
			font-size: 50px;
			line-height: 33px;
			margin-bottom: 10px;
		}

		.history .col-sm-4 {
			margin-bottom: 20px;
		}

		.history h3 span {
			padding: 0 12px;
		}

		.home-projects .row {
			border-top: 0;
			
		}

		.home-projects h2 {
			margin-bottom: 10px;
		}

		.history span {
			font-size: 14px;
			line-height: 16px;
		}
	}

	.come-in {
		-webkit-transform: translateY(50px);
		transform: translateY(50px);
		-webkit-animation: come-in 0.6s ease forwards;
		animation: come-in 0.6s ease forwards;
	}

	.come-in.from-right {
		-webkit-transform: translateY(0) translateX(50px);
		transform: translateY(0) translateX(50px);
	}

	.come-in.from-left {
		-webkit-transform: translateY(0) translateX(-50px);
		transform: translateY(0) translateX(-50px);
	}

	.come-in.from-top {
		-webkit-transform: translateY(-50px);
		transform: translateY(-50px);
	}

	.come-in:nth-child(odd) {
		-webkit-animation-duration: 1s;
		animation-duration: 1s;
		/* So they look staggered */
	}

	@-webkit-keyframes come-in {
		to {
			-webkit-transform: translateY(0);
			transform: translateY(0);
			opacity: 1;
		}
	}

	@keyframes come-in {
		to {
			-webkit-transform: translateY(0);
			transform: translateY(0);
			opacity: 1;
		}
	}

	.row.timeline,
	.row.timeline .row {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		margin-right: -15px;
		margin-left: -15px
	}

	.row.timeline {
		padding-bottom: 35px
	}

	.timeline .col-md-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
		position: relative;
	}

	.timeline .col-md-12 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%
	}

	.timeline .tmlabel {
		border: 1px solid #d6d9dc;
		padding: 13px;
		display: flex;
		align-items: center;
		margin-top: 14px;
		margin-bottom: 14px
	}

	.timeline .tmlabel div {
		background: #f1f1f1;
		text-align: center;
		padding: 15px;
		display: flex;
		flex-direction: column;
		height: 150px;
		width: 100%;
		justify-content: center;
		align-items: center
	}

	.timeline .year {
		text-align: center;
		padding: 15px;
		display: flex;
		height: 206px;
		flex-direction: column;
		justify-content: center;
		color: #ffcb00 !important;
		font-size: 2rem
	}

	.timeline .row,
	.timeline .year {
		align-items: center
	}

	.timeline .col-md-6:first-child::before {
		top: 0;
		bottom: 0;
		right: -24px;
		width: 1px;
		content: '';
		background: #d6d9dc;
		position: absolute;
		z-index: 3;
		margin-left: 0
	}

	.timeline .ls::after,
	.timeline .rs::before {
		content: " ";
		height: 22px;
		width: 11px;
		position: absolute;
		pointer-events: none;
		z-index: 3;
		top: 44%
	}

	.timeline .tmlabel div {
		background: #f1f1f1;
		text-align: center;
		padding: 15px;
		display: flex;
		flex-direction: column;
		height: 150px;
		width: 100%;
		justify-content: center;
		align-items: center
	}

	.timeline .tmlabel div p {
		font-size: 15px;
		line-height: 21px;
		font-weight: 400;
		margin-bottom: 0
	}

	.timeline .ls::after {
		right: 2px;
		background: url(/content/assets/img/label_arrow2.png) no-repeat
	}

	.timeline .rs::before {
		left: 2px;
		background: url(/content/assets/img/label_arrow.png) no-repeat
	}

	.timeline .year {
		text-align: center;
		color: #ffcb00 !important;
		font-size: 2rem
	}

	.timeline .col-md-6:first-child {
		margin-right: 25px;
		flex: 0 0 46%;
		max-width: 46%
	}

	.timeline .col-md-6:last-child {
		margin-left: 25px;
		flex: 0 0 46%;
		max-width: 46%
	}

	.mobyear {
		display: none
	}

	.timeline .cbp_tmicon {
		top: 45%;
		left: -37px;
		width: 20px;
		height: 20px;
		position: absolute;
		background: url(/content/assets/img/dot.png) no-repeat;
		margin: 0;
		z-index: 3
	}

	.timeline .col-md-6:first-child::before {
		top: 0;
		bottom: 0;
		right: -24px;
		width: 1px;
		content: '';
		background: #d6d9dc;
		position: absolute;
		z-index: 3;
		margin-left: 0;
	}

	@media (min-width: 992px) and (max-width: 1200px) {
		.timeline .tmlabel div {
			height: 180px;
		}

		.timeline .year {
			height: 236px;
		}
	}

	@media (max-width: 768px) {

		.timeline .col-md-6 {
			flex: 0 0 100% !important;
			max-width: 100% !important;
		}

		.tabs-title a {
			width: 49% !important;
		}

		.timeline .col-md-6:first-child::before, .timeline .cbp_tmicon, .timeline .ls::after, .timeline .rs::before {
			display: none;
		}

		.timeline .tmlabel div p.mobyear {
			display: block;
			text-align: center;
			font-weight: 300;
			color: #ffcb00 !important;
			font-family: "Noway-Light",Helvetica,Arial,sans-serif !important;
			font-size: 1.3rem;
			margin-bottom: 6px;
		}

		.timeline .year {
			display: none;
		}

		.timeline .col-md-6:last-child {
			margin-left: 0;
		}

		.timeline .tmlabel div {
			height: auto;
		}

		.timeline .year {
			height: auto;
		}

		h1 {
			font-size: 30px;
		}
	}



	@media (min-width: 411px) and (max-width: 510px) {

		#cconsent-bar button {
			padding: 6px 6px !important;
			font-size: 12px !important
		}

		#cconsent-bar {
			text-align: left !important;
		}
	}

	@media (max-width: 410px) {

		#cconsent-bar .ccb__right {
			white-space: normal !important;
		}

		#cconsent-bar {
			text-align: left !important;
		}

		#cconsent-bar button {
			margin-right: 0;
			margin-bottom: 5px;
			width: 100%;
		}
	}

	@media (min-width: 511px) and (max-width: 800px) {

		#cconsent-bar {
			text-align: left !important;
		}
	}

	@media (min-width: 768px) {
		#cconsent-bar .ccb__left {
			padding-right: 15px;
		}
	}

	@media (max-width: 500px) {

		#background-video {
			display: none;
		}

		#background-video-mobile {
			display: block !important;
		}

		.subtitle {
			background: #ffcd0a;
			font-size: 28px;
			font-weight: 600;
			opacity: .9;
			height: 150px;
			margin-top: 238px;
			padding: 16px 30px 0;
			line-height: 35px;
			text-transform: uppercase;
		}
	}

	@media (max-width: 370px) {

		.home-top a {
			font-size: 15px;
		}
	}

@media (min-width: 500px) and (max-width: 767px) {


	.d-block-mobile2 .col-lg-12 {
		display: flex;
		flex-wrap: wrap;
	}

	.d-block-mobile2 .col-lg-12 p {
		flex-grow: 1;
		margin-top: 27px;
		width: 50%;
	}

	.d-block-mobile2 .col-lg-12 p:first-child {
		margin-top: 0;
	}
}

.contactus {
	background: #f9f9f9;
	padding: 40px 0;
}

.contactus h2 {
	margin-bottom: 20px;
}

.contact-info h3 {
	font-size: 18px;
	font-weight: 600;
}

.contact-table-info {
	width: 100%;
}

.contact-table-info td {
	padding: 4px 10px;
}

.contact-table-info td:first-child {
	padding-left: 0;
}

.contact-row {
	margin-bottom: 20px;
	padding-bottom: 20px;
	border-bottom: 1px solid #eee;
}

.contact {
	margin-bottom: 40px;

}

.contact form {
	padding: 30px 30px;
	background: #eee;
}

.contact form .btn-primary {
	width: 100%;
}

.contact h2 {
	font-size: 1.8rem;
	margin-bottom: 20px;
}

textarea {
	height: 140px;
}

.fa-phone {
	width: 17px;
	height: 16px;
	vertical-align: baseline;
	margin-right: 6px;
	display: inline-block;
	background: url("/content/assets/img/ico_phone.png") no-repeat;
}

.fa-fax {
	width: 17px;
	height: 16px;
	display: inline-block;
	margin-right: 6px;
	vertical-align: baseline;
	background: url("/content/assets/img/ico_fax.png") no-repeat;
}

.fa-envelope {
	width: 17px;
	height: 16px;
	display: inline-block;
	vertical-align:bottom;
	margin-right: 6px;
	background: url("/content/assets/img/ico_mail.png") no-repeat;
}

@media (max-width: 767px) {

	.contact-table-info {
		margin-bottom: 30px;
	}
}


@media (min-width: 768px) and (max-width: 991px) {
	.col-md-5.contact, .contact-info.col-md-7 {
		width: 100%;
	}

	.contact-table-info {
		margin-bottom: 30px;
	}

}

@media (min-width: 992px) and (max-width: 1200px) {

	.contact-table-info {
		margin-bottom: 30px;
	}
	
}

@media (max-width: 500px) {

	.contact-row .col-sm-6 {
		width: 100%;
	}
}

@media (min-width: 501px) and (max-width: 767px) {
	.contact-row .col-sm-6.col-md-8 .col-sm-6.col-md-6 {
		width: 100%;
	}


}

#map1, #map2 {
	scroll-margin-top: 140px;
}

html {
	scroll-behavior: smooth;
}

@media (min-width: 992px) {

	.submenu .active a, .nav .active > a, .submenu a:hover, .nav a:hover {
		color: #ffcb00 !important;
	}
}

@media (max-width: 450px) {

	.tabs-title a {
		width: 100% !important;
	}
}

.js-fancybox .bi {
	position: absolute;
	top: 41%;
	left: 41%;
}

.mgb-30 {
	margin-bottom: 30px;
}

.date {
	color: #858585;
}

@media (min-width: 992px) {

	.container.content {
		min-height: 450px;
	}
}

.btn-languages {
	border: 0;
	color: #fff;
	margin-top: 35px;
	background: none;
	padding-bottom: 5px;
}

.dropdown-toggle.btn-languages::after {
	display: none;
}

.dropdown-toggle.btn-languages img {
	color: #fff !important;
	fill: #fff !important;
	width: 32px !important;
}

.lang .dropdown-menu[data-bs-popper] {
	right: 0 !important;
	left: auto !important;
	width: 80px;
	min-width: 40px;
}

.lang .dropdown-menu[data-bs-popper] ul, .lang .dropdown-menu[data-bs-popper] ul li {
	width: 40px;
}

.lang {
	margin-left: 20px;
}

@media (max-width: 991px) {

	.lang {
		display: none;
	}

	.lang-mobile {
		display: block !important;
		margin-top: 10px !important;
		padding: 8px 12px 0 12px !important;
		text-align: center !important;
		border-top: 1px solid #3d4757 !important;
	}

	.lang-mobile a {
		color: #fff !important;
		margin: 0 6px !important;
	}


	.rokov {
		position: relative;
		left: -173px;
		top: -1px;
	}
}

@media (max-width: 768px) {

	.rokov {
		position: relative;
		left: -76px;
		top: -1px;
	}
}

@media (max-width: 575px) {

	.rokov img {
		width: 60px !important;
	}

	.rokov {
		position: absolute;
		left: 110px;
		top: 19px;
	}
}

@media (min-width: 576px) {


	.lang-mobile {
		padding: 8px 12px 14px 12px !important;
	}

	
}


.rokov {
	margin-left: 10px !important;
}